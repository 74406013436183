/* eslint-disable no-console */
/*
 * Copyright 2021 Larder Software Limited
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, {useCallback, useEffect, useState} from 'react';
import {Progress,} from '@backstage/core-components';
import {
  ApiHolder,
  ErrorApi,
  errorApiRef,
  githubAuthApiRef,
  SessionState,
  useApi,
  useApiHolder,
} from '@backstage/core-plugin-api';
import {Button, Grid, Tooltip, Typography} from '@material-ui/core';
import useAsync from 'react-use/lib/useAsync';
import {GithubApi} from "../../apis/GithubApi";
import {githubApiRef} from "../../apis/GithubApiRef";
import {GithubClient} from "../../apis/GithubClient";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeMermaid from "rehype-mermaid";
import remarkGfm from "remark-gfm";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {vscDarkPlus} from 'react-syntax-highlighter/dist/cjs/styles/prism';


export type MarkdownContentProps = {
  baseUrl?: string;
  owner: string;
  repo: string;
  path: string;
  branch?: string;
  preserveHtmlComments?: boolean;
};

const getGithubClient = (apiHolder: ApiHolder, errorApi: ErrorApi) => {
  let githubClient: GithubApi | undefined = apiHolder.get(githubApiRef);
  if (!githubClient) {
    const auth = apiHolder.get(githubAuthApiRef);
    if (auth) {
      githubClient = new GithubClient({githubAuthApi: auth, errorApi});
    }
  }
  if (!githubClient) {
    throw new Error(
      'The MarkdownCard component Failed to get the github client',
    );
  }
  return githubClient;
};

const CodeBlock = {
// @ts-ignore
  code({node, inline, className, children, ...props}) {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <SyntaxHighlighter
        style={vscDarkPlus}
        language={match[1]}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

const GithubFileContent = (props: MarkdownContentProps) => {
  const {preserveHtmlComments} = props;
  const apiHolder = useApiHolder();
  const errorApi = useApi(errorApiRef);

  const {value, loading, error} = useAsync(async () => {
    const githubClient = getGithubClient(apiHolder, errorApi);
    return githubClient.getContent({...props});
  }, [apiHolder]);

  const transformImageUri = useCallback(
    (href: string) => {
      console.log("url", href);
      return value?.media[href] || href;
    },
    [value?.media],
  );

  const transformLinkUri = useCallback(
    (href: string) => {
      console.log("link", href);
      return value?.links[href] || href;
    },
    [value?.links],
  );

  if (loading) {
    return <Progress/>;
  } else if (error) {
    return <span>{error.message}</span>;
  }

  if (!value) {
    return <Progress/>;
  }

  let content = value.content;
  if (!preserveHtmlComments) {
    content = content.replace(/<!--.*?-->/g, '');
  }

  return (
    <ReactMarkdown children={content}
                   transformImageUri={transformImageUri}
                   transformLinkUri={transformLinkUri}
                   remarkPlugins={[remarkGfm]}
                   components={CodeBlock as any}
                   rehypePlugins={[
                     rehypeRaw as any,
                     rehypeMermaid,
                   ]}
    />
  );
};

const GithubNotAuthorized = () => {
  const githubApi = useApi(githubAuthApiRef);
  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography>
          You are not logged into github. You need to be signed in to see the
          content of this card.
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="flex-end">
        <Tooltip placement="top" arrow title="Sign in to Github">
          <Button
            variant="outlined"
            color="primary"
            // Calling getAccessToken instead of a plain signIn because we are going to get the correct scopes right away. No need to second request
            onClick={() => githubApi.getAccessToken('repo')}
          >
            Sign in
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

/**
 * A component to render a markdown file from github
 *
 * @public
 */
const MarkdownContent = (props: MarkdownContentProps) => {
  const githubApi = useApi(githubAuthApiRef);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const authSubscription = githubApi.sessionState$().subscribe(state => {
      if (state === SessionState.SignedIn) {
        setIsLoggedIn(true);
      }
    });
    return () => {
      authSubscription.unsubscribe();
    };
  }, [githubApi]);

  return isLoggedIn ? (
    <GithubFileContent {...props} />
  ) : (
    <GithubNotAuthorized/>
  );
};

export default MarkdownContent;