import React from 'react';
import {useEntity} from '@backstage/plugin-catalog-react';
import {InfoCard} from '@backstage/core-components';
import {makeStyles} from '@material-ui/core';
import {useEntityGithubScmIntegration} from "../../hooks/useEntityGithubScmIntegration";
import {useProjectEntity} from "../../hooks/useProjectEntity";
import MarkdownContent from "./MarkdownContent";

const useStyles = makeStyles(theme => ({
  infoCard: {
    marginBottom: theme.spacing(3),
    '& + .MuiAlert-root': {
      marginTop: theme.spacing(3),
    },
    '& .MuiCardContent-root': {
      padding: theme.spacing(2, 1, 2, 2),
    },
  },
  readMe: {
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      border: '1px solid #555555',
      backgroundColor: '#555',
      borderRadius: '4px',
    },
  },
}));

type ReadMeCardProps = {
  maxHeight?: number;
  title?: string;
};

const ExtendedReadMeCard = (props: ReadMeCardProps) => {
  const {entity} = useEntity();
  const {owner, repo, readmePath} = useProjectEntity(entity);
  const {hostname, baseUrl} = useEntityGithubScmIntegration(entity);
  const classes = useStyles();

  const linkPath = readmePath || 'README.md';

  return (
    <InfoCard
      title={props.title || 'Github Readme'}
      className={classes.infoCard}
      deepLink={{
        link: `//${hostname}/${owner}/${repo}/blob/HEAD/${linkPath}`,
        title: 'Github Readme',
        onClick: e => {
          e.preventDefault();
          window.open(`//${hostname}/${owner}/${repo}/blob/HEAD/${linkPath}`);
        },
      }}
    >
      <div
        className={classes.readMe}
        style={{
          maxHeight: `${props.maxHeight}px`,
        }}
      >
        <MarkdownContent
          baseUrl={baseUrl}
          owner={owner}
          repo={repo}
          path={readmePath}
        />
      </div>
    </InfoCard>
  );
};

export default ExtendedReadMeCard;