import React from 'react';
import {Navigate, Route} from 'react-router';
import {apiDocsPlugin, ApiExplorerPage} from '@backstage/plugin-api-docs';
import {CatalogEntityPage, CatalogIndexPage, catalogPlugin,} from '@backstage/plugin-catalog';
import {CatalogImportPage, catalogImportPlugin,} from '@backstage/plugin-catalog-import';
import {ScaffolderPage, scaffolderPlugin} from '@backstage/plugin-scaffolder';
import {orgPlugin} from '@backstage/plugin-org';
import {SearchPage} from '@backstage/plugin-search';
import {TechRadarPage} from '@backstage/plugin-tech-radar';
import {techdocsPlugin,} from '@backstage/plugin-techdocs';
import {UserSettingsPage} from '@backstage/plugin-user-settings';
import {apis} from './apis';
import {entityPage} from './components/catalog/EntityPage';
import {searchPage} from './components/search/SearchPage';
import {Root} from './components/Root';

import {AlertDisplay, OAuthRequestDialog, SignInPage} from '@backstage/core-components';
import {createApp} from '@backstage/app-defaults';
import {AppRouter, FlatRoutes} from '@backstage/core-app-api';
import {CatalogGraphPage, catalogGraphPlugin} from '@backstage/plugin-catalog-graph';
import {RequirePermission} from '@backstage/plugin-permission-react';
import {catalogEntityCreatePermission} from '@backstage/plugin-catalog-common/alpha';
import {githubAuthApiRef} from "@backstage/core-plugin-api";

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'github-auth-provider',
          title: 'GitHub',
          message: 'Sign in using GitHub',
          apiRef: githubAuthApiRef,
        }}
      />
    ),
  },
  bindRoutes({bind}) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog"/>}/>
    <Route path="/catalog" element={<CatalogIndexPage/>}/>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage/>}
    >
      {entityPage}
    </Route>
    <Route path="/create" element={<ScaffolderPage/>}/>
    <Route path="/api-docs" element={<ApiExplorerPage/>}/>
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800}/>}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage/>
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage/>}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage/>}/>
    <Route path="/catalog-graph" element={<CatalogGraphPage/>}/>
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay/>
    <OAuthRequestDialog/>
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>
);
// );

